<div class="container"></div>
<footer fxLayoutAlign="center end" fxLayout="row wrap">
  <!-- Footer main -->
  <div class="flex-1600px flex-100-lt-lg">
    <section
      *ngIf="menus"
      class="ft-main"
      fxLayout="row"
      fxLayout.lt-md="column"
    >
      <div class="ft-main-item" *ngFor="let menu of menus" fxFlex="25%">
        <p class="ft-title">{{ menu.title }}</p>
        <div *ngIf="menu && menu.content" [innerHtml]="menu.content"></div>
        <ul *ngFor="let item of menu.items">
          <li>
            <a [routerLink]="item.url">{{ item.caption }}</a>
          </li>
        </ul>
      </div>
      <div class="ft-main-item" fxFlex="25%">
        <p class="ft-title">&nbsp;</p>
        <div fxLayout="row" fxLayoutGap="40px">
          <div>
            <a href="https://twitter.com/LicenceExchange?lang=en"
              >&nbsp;
              <img
                [src]="'../../../../assets/img/footer/twitter_white.svg'"
                alt="Twitter Logo"
                onmouseover="this.src='../../../../assets/img/footer/twitter_green.svg';"
                onmouseout="this.src='../../../../assets/img/footer/twitter_white.svg';"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/company/li-x-gmbh"
              >&nbsp;
              <img
                [src]="'../../../../assets/img/footer/linkden_white.svg'"
                alt="LinkedIn Logo"
                onmouseover="this.src='../../../../assets/img/footer/linkden_green.svg';"
                onmouseout="this.src='../../../../assets/img/footer/linkden_white.svg';"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <a href="https://www.xing.com/companies/li-xgmbh"
              >&nbsp;
              <img
                [src]="'../../../../assets/img/footer/xing_white.svg'"
                alt="Xing Logo"
                onmouseover="this.src='../../../../assets/img/footer/xing_green.svg';"
                onmouseout="this.src='../../../../assets/img/footer/xing_white.svg';"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div class="copyright">© 2025 Copyright li-x GmbH</div>
      </div>
    </section>
  </div>
</footer>
